// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/
@import './material';
@import './buttons';
@import './palette';
@import './form';
@import './alert';
@import '@angular/material/theming';

$custom-typography: mat-typography-config(
        $font-family: '"Proxima-Nova"'
);
@include mat-core($custom-typography);

$my-app-primary: mat-palette($mat-tfc-primary, 400, 800, 900);
$my-app-accent:  mat-palette($mat-tfc-accent, 500, 900, A100);
$my-app-warn:    mat-palette($mat-tfc-warn);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

:root {
    --ion-font-family: "Proxima-Nova";

    --ion-text-color: #363531;
    --ion-text-color-grey: #EBEBEB;
    --ion-text-color-shadow: #E2E2E2;

    --ion-background-color: #f9f9f9;
    --grey-background: #ececec;
    --grey-card-background: #FBFBFB;

    --ion-toolbar-background: #f9f9f9;

    --input-border-color: #b5b5b5;

    /** primary **/
    --ion-color-primary: #f5a623;
    --ion-color-primary-rgb: 245, 166, 35;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #d8921f;
    --ion-color-primary-tint: #f6af39;

    /** cta **/
    --ion-color-secondary: #50e3c2;
    --ion-color-secondary-rgb: 80, 227, 194;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #46c8ab;
    --ion-color-secondary-tint: #62e6c8;

    /** black proacciona **/
    --ion-color-dark: #363531;
    --ion-color-dark-rgb: 54, 53, 49;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #302f2b;
    --ion-color-dark-tint: #4a4946;

    /** error formularios **/

    --ion-color-success: #358C00;
    --ion-color-success-rgb: 53,140,0;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #2f7b00;
    --ion-color-success-tint: #49981a;

    --ion-color-danger: #E35050;
    --ion-color-danger-rgb: 227,80,80;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #c84646;
    --ion-color-danger-tint: #e66262;

    --ion-color-lines-tables: #ebebeb;
    --ion-color-lines-tables-rgb: 235, 235, 235;
    --ion-color-lines-tables-contrast: #000000;
    --ion-color-lines-tables-contrast-rgb: 0, 0, 0;
    --ion-color-lines-tables-shade: #cfcfcf;
    --ion-color-lines-tables-tint: #ededed;

    --ion-color-icon-menubar: #979797;
    --ion-color-icon-menubar-rgb: 151, 151, 151;
    --ion-color-icon-menubar-contrast: #000000;
    --ion-color-icon-menubar-contrast-rgb: 0, 0, 0;
    --ion-color-icon-menubar-shade: #858585;
    --ion-color-icon-menubar-tint: #a1a1a1;

    --ion-color-navbar-grey: #EBEBEB;
    --ion-color-navbar-grey-rgb: 235,235,235;
    --ion-color-navbar-grey-contrast: #000000;
    --ion-color-navbar-grey-contrast-rgb: 0,0,0;
    --ion-color-navbar-grey-shade: #cfcfcf;
    --ion-color-navbar-grey-tint: #ededed;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;
}

.ion-color-navbar-grey {
    --ion-color-base: var(--ion-color-navbar-grey);
    --ion-color-base-rgb: var(--ion-color-navbar-grey-rgb);
    --ion-color-contrast: var(--ion-color-navbar-grey-contrast);
    --ion-color-contrast-rgb: var(--ion-color-navbar-grey-contrast-rgb);
    --ion-color-shade: var(--ion-color-navbar-grey-shade);
    --ion-color-tint: var(--ion-color-navbar-grey-tint);
}

.ion-color-icon-menubar {
    --ion-color-base: var(--ion-color-icon-menubar);
    --ion-color-base-rgb: var(--ion-color-icon-menubar-rgb);
    --ion-color-contrast: var(--ion-color-icon-menubar-contrast);
    --ion-color-contrast-rgb: var(--ion-color-icon-menubar-contrast-rgb);
    --ion-color-shade: var(--ion-color-icon-menubar-shade);
    --ion-color-tint: var(--ion-color-icon-menubar-tint);
}

.ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
}

ion-button.ion-color-white {
    --color: var(--ion-text-color);
    --border-color: var(--ion-text-color-grey);
    --border-style:solid;
    --border-width: 1px;
}

ion-badge.ion-color-white  {
    border: 1px solid var(--ion-text-color-grey);
}

ion-toolbar {
    --border-style: none;
    --padding-bottom: 25px;
}

ion-modal {
    --ion-background-color: #FFFFFF;
    --border-radius: none;
}
ion-input input{
    --placeholder-color: var(--ion-text-color);
    --placeholder-opacity: 0.75;
    padding-left: 15px !important;
}

ion-label{
    font-weight: bold;
}

ion-card {
    --background: #fff;
    --color: var(--ion-text-color);
    box-shadow: none;
    margin-bottom: 0;
}

ion-card-header {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 20px;
    color: var(--ion-text-color);
}

.modal-card ion-card-header{
    padding-left: 20px;
}

ion-button {
    height: 2.4em;
}

ion-button ion-icon {
    height: 0.9em;
}

.toolbar-background {
    border: none !important;
}

ion-segment{
    margin-bottom: 20px;
    border-bottom: 1px solid var(--ion-text-color-grey);
    ion-segment-button{
        max-width: 500px;
        ion-label{
            text-transform: none;
        }
    }
    .segment-button-checked ion-label{
        color: var(--ion-text-color);
    }
    .segment-button-checked:hover ion-label{
        color: var(--ion-text-color);
    }
    .segment-button-after-checked ion-label{
        color: var(--ion-text-color-grey);
    }
}

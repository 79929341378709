/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url(./assets/fonts/font-family.css);
@import "@angular/cdk/overlay-prebuilt.css";

$font-family-base: "Proxima-Nova", sans-serif !default;

.right-side-modal-css::part(content),
.right-side-modal-css .modal-shadow {
  position: absolute;
  height: 100%;
  width: 50%;
  min-width: 767px;
  display: block;
  right: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media (max-width: 767px) {
  .right-side-modal-css::part(content),
  .right-side-modal-css .modal-shadow {
    width: 100% !important;
    min-width: auto;
  }
}

.help-img-container {
  position: relative;
  display: inline-block;
  top: 7px;

  ion-icon {
    padding-left: 10px;
    font-size: 32px;
  }
}

ion-modal .modal-header {
  padding-right: 45px;
}

ion-modal .modal-title {
  margin-top: 0.85em;
  font-weight: bold;
  font-size: 23px;
}

ion-modal .modal-subtitle {
  padding-left: 70px;
}

ion-card {
  margin-top: 5px !important;
}

ion-card.modal-card {
  border: 1px solid var(--ion-color-lines-tables);
  margin: 0 50px;
}

ion-card.modal-card.no-border {
  border: none;
}

ion-card.modal-card ion-card-content {
  padding: 10px 0px 0 0px;
}

ion-card ion-grid {
  padding: 0 15px;
}

.modal-card ion-card-subtitle {
  font-weight: normal;
  padding-top: 5px;
  text-transform: none;
}

.padding-col-auto {
  padding: 0 10px;
}

.input-item {
  border-color: var(--input-border-color) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 6px !important;
  --padding-start: 10px;
  height: 55px;
}

.input-item.mat-input-element {
  padding-left: 15px;
}

.mat-tab-label {
  opacity: 1 !important;

  b {
    opacity: 0.5 !important;
  }

  .mat-tab-label-content {
    ion-badge {
      margin-left: 8px;
      padding: 0;
      font-weight: normal;
      border-radius: 50px;
      line-height: 24px;
      height: 24px;
      width: 24px;
    }
  }
}

.mat-tab-label-active {
  b {
    opacity: 1 !important;
  }
}

ion-badge.ion-color-light {
  background-color: #fff;
  border: 1px solid var(--ion-text-color-grey);
  font-weight: 400;
  font-size: 0.7rem;
}

//ion-badge.card-badge.ion-color-secondary {
//    background-color: #fff;
//    color: var(--ion-text-color);
//    border: 1px solid var(--ion-color-secondary);
//    font-weight: 400;
//    font-size: 0.7rem;
//}
//
//ion-badge.card-badge.ion-color-danger {
//    background-color: #fff;
//    color: var(--ion-text-color);
//    border: 1px solid var(--ion-color-danger);
//    font-weight: 400;
//    font-size: 0.7rem;
//}

.spinner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 56px;
  padding: 0 18px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.no-padding {
  padding: 0 !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  margin: 0 0 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.25);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.modal-scrollable-content {
  height: calc(100vh - 172px);
  overflow-y: auto;
}

app-allergen-popover {
  margin: 0 3px -5px;
}

.graph-no-data {
  .center {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    padding: 0 50px;
  }

  height: 270px;
  line-height: 270px;
  text-align: center;
  margin: 0px;
  background-image: url(assets/images/bg-graph.png);
  background-position: center bottom;
  background-repeat: no-repeat;
}

.wkt-zone {
  z-index: 1 !important;
  border-radius: 10px;
}

.info-dialog {
  .mat-dialog-container {
    padding: 45px;
    border-radius: 20px;
    text-align: center;
    min-width: 300px;
  }
}

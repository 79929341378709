input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--ion-text-color);
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
}


//##################### MAT-VALID styles for mat input valid ###############################
.mat-form-field-appearance-outline.mat-valid input{
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled).mat-valid .mat-form-field-flex .mat-form-field-outline-thick {
    opacity: 1;
    border-width: 1px;
}
.mat-form-field-appearance-outline.mat-valid .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-valid .mat-form-field-label{
    color: #28a745;
}

.valid-feedback {
    //display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}

.mat-success {
    color: #28a745 !important;
}
.mat-success[disabled] {
    color: rgba(0, 0, 0, 0.12) !important;
}


//##################### end MAT-VALID styles for mat input valid ###############################

//##################### MAT-INVALID styles for mat input valid ###############################

.mat-form-field-appearance-outline.mat-invalid input{
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.invalid-feedback {
    //display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

//##################### end MAT-INVALID styles for mat input valid ###########################


form .mat-datepicker-input.is-invalid{
    background-image: none;
    padding-right: calc(3.2em + 0.75rem) !important;
}

form .mat-datepicker-toggle.is-invalid {
    padding-right: 0 !important;
    padding-left: 20px !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
    background-position: left center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.mat-form-field-type-mat-select.is-invalid{
    padding-right: 0 !important;
    background-image: none !important;
}


textarea:invalid, textarea.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

ngx-daterangepicker-material button{
    color: var(--ion-text-color);
}

.input-md-drppicker{
    background-color: #fff;
    color: black;
    border: none;
    border-radius: 50px;
    margin-right: 24px;
    padding: 8px 16px;
    width: 174px;
    margin: 10px 0;
}
.input-md-drppicker:focus{
    outline: none;
}

.md-drppicker{
    padding: 0 !important;
    .ranges ul li button {
        padding: 17.2px 17px !important;
    }
    .ranges ul li button.active {
        background-color: var(--ion-color-primary) !important;
    }
    .ranges ul li button:focus{
        outline: none;
    }
    th{
        color: var(--ion-text-color) !important;
    }
    td.active, .md-drppicker td.active:hover {
        background-color: var(--ion-color-primary) !important;
    }
    .btn{
        background-color: var(--ion-color-primary) !important;
        border-radius: 50px !important;
        padding: 0 15px !important;
        box-shadow: none !important;
        margin-right: 15px !important;
        text-transform: capitalize !important;
        color: #fff !important;
    }
    .calendar-table{
        padding: 0 !important;
    }
    .calendar {
        padding: 5px;
    }
}

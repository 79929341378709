ion-button.add-new-button {
    margin-left: 20px;
    margin-bottom: 20px;
    --box-shadow: 0px 4px 4px rgba(163, 163, 163, 0.25);
    --border-radius: 50px;
}

ion-button.add-new-button.inverted {
    margin-left: 20px;
    margin-bottom: 20px;
    --box-shadow: 0px 4px 4px rgba(163, 163, 163, 0.25);
    --color: var(--ion-color-primary);
    --border-radius: 50px;
    --background: #ffffff;
    --background-activated: #f5f5f5;
    --background-focused: #f5f5f5;
    --background-hover: #f5f5f5;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-primary);
}

ion-button.default-button {
    --color: var(--ion-text-color);
    --border-radius: 50px;
    --background: #ffffff;
    --background-activated: #ffffff;
    --background-focused: #ffffff;
    --background-hover: #ffffff;
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-lines-tables);
}

.btn-circle {
    --padding-start: 0;
    --padding-end: 0;
    --border-radius: 50px;
    margin: 0 10px;
    height: 40px;
    width: 40px;
    ion-icon {
        width: 30px;
        height: 40px;
    }
}

.btn-circle.btn-close {
    margin: 12px 15px 0 15px;
    ion-icon {
        width: 18px;
        height: 18px;
    }
}

.btn-circle.btn-primary{
    --background: var(--ion-color-primary);
}

.btn-circle.btn-primary:focus {
    --box-shadow: 0 0 0 1px var(--ion-color-primary-shade);
}
.btn-circle.btn-secondary{
    --background: var(--ion-color-secondary);
}

.btn-circle.btn-secondary:focus {
    --box-shadow: 0 0 0 1px var(--ion-color-secondary-shade);
}

.btn-circle.btn-danger{
    --background: var(--ion-color-danger);
    --color: #ffffff;
}

.btn-circle.btn-danger:focus {
    --box-shadow: 0 0 0 1px var(--ion-color-danger-shade);
}

.btn-circle.btn-grey {
    --background: var(--ion-text-color-grey);
}

.btn-circle.btn-grey:focus {
    box-shadow: 0 0 0 1px var(--ion-text-color-shadow);
}

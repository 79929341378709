
.mat-header-cell {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: var(--ion-text-color) !important;
}

.mat-cell {
  color: var(--ion-text-color) !important;
}

mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: var(--ion-background-color) !important;
}

.full-width {
  width: 100% !important;
}

//.full-width .mat-form-field-wrapper {
//    padding-bottom: 0 !important;
//}

.full-width.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.3em 0.1em 0.75em 0 !important;
}

.full-width.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #ffffff !important;
  border-color: var(--input-border-color) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 7px !important;
  padding: 0 .75em 0 1em !important;
  height: 55px;
}

.full-width .mat-form-field-underline {
  display: none !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}

.full-width.mat-form-field-label-wrapper {
  top: -0.35375em !important;
  padding-top: 0.84375em !important;
}

.full-width.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.full-width.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  display: none !important;
}

//.mat-datepicker-toggle{
//    position: absolute;
//    right: 8px;
//    top: 32px;
//}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
  right: -5px !important;
}

.mat-table-action-button {
  --background: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
  --background-activated: transparent !important;
  --color: var(--ion-text-color) !important;
  --color-activated: var(--ion-text-color-grey) !important;
  --color-focused: var(--ion-text-color-grey) !important;
  --color-hover: var(--ion-text-color-grey) !important;
}

.mat-table-button {
  padding: 0;
  margin: 0;

  ion-icon {
    font-size: 25px;
  }
}

.mat-row {
  min-height: 40px;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 1em 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.45em) scale(0.75) !important;
}

.mat-form-field-label-wrapper {
  top: -1.1em !important;
}


.mat-hint .invalid-feedback,
.mat-hint .valid-feedback {
  font-size: 100% !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  padding: 16px;

  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 5px;
    line-break: unset;
    width: auto;
    overflow: hidden;
    vertical-align: middle;
  }
}

.mat-primary-icon {
  color: #f5a623;
}

/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.mat-primary-icon svg {
  fill: #f5a623;
}

.mat-card {
  border-radius: 8px !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 5px !important;
}

.mat-card-header {
  font-size: 24px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
  color: var(--ion-text-color);
}

.mat-card-content {
  margin-bottom: 0 !important;
}

.mat-card-actions {
  border-top: 1px solid var(--ion-color-lines-tables);
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 5px 0 !important;
}

.checkbox-container {
  padding-bottom: 1.1rem;
}

.mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--ion-color-secondary) !important;
}

.mat-radio-button .mat-radio-inner-circle {
  background-color: var(--ion-color-secondary-shade) !important;
}

.filter-input {
  position: absolute !important;
  right: 18px;
  top: 15px;
  font-size: 14px;
  max-width: 300px;
  width: 100%;

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    top: .40em !important;
    right: 0 !important;
  }
  .mat-form-field {
    max-width: 300px;
    width: 100%;
  }
}
